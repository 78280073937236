import React from 'react';
import Header from "../components/Header";
import blog1 from '../img/blog1.jpg';
import blog2 from '../img/blog2.png';
import jeroen from "../img/jeroen-2.jpg";
import tycho from "../img/tycho.jpg";
import Footer from "../components/Footer";
import Animate from "../components/Animate";
import firebase from "firebase";
import {Link} from "react-router-dom";

class Thought extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
    }
    render() {
        return (
            <>
                <Header active='thoughts' />
                <div className='wrapper max-w-screen-lg pt-8 md:pt-24'>
                    <div className='mx-auto max-w-screen-md'>
                        <Animate delay={0}><h2 className='text-4xl md:text-5xl font-bold leading-tight md:leading-snug'>Most services are now digital. The next step? Making them human.</h2></Animate>
                        <Animate delay={1000}>
                            <div className='flex items-center mt-5'>
                                <div className='h-10 w-10 -mr-2 relative z-10 border-white border rounded-full flex-none bg-center bg-cover' style={{backgroundImage: `url(${tycho})`}} />
                                <div className='h-10 w-10 mr-3 rounded-full border-white border flex-none bg-center bg-cover' style={{backgroundImage: `url(${jeroen})`}} />
                                <div className='text-lg'>By Tycho Litjens & Jeroen Riemens <span className='mx-1'>·</span> Insights <span className='mx-1'>·</span> 20 July 2023</div>
                            </div>
                        </Animate>
                    </div>
                    <Animate delay={1100}><img src={blog1} className='w-full block my-12' /></Animate>
                    <Animate delay={1200}><div className='mx-auto max-w-screen-md text-lg md:text-xl pb-24 leading-relaxed' style={{lineHeight: 1.625}}>
                        <div className='max-w-prose'>
                            <p className='text-2xl md:pr-10' style={{lineHeight: 1.625}}>Suppose you have to live without a mobile phone for a week. No, actually – no cheating. Which apps would you really miss? Most people generally answer this question with only four apps:  email, Google Maps, Spotify and WhatsApp.</p>
                            <p className='mt-7'>A giant shoutout to the music player, messaging app and digital map, but is this what ten years of innovation has brought us? If you look further into this, most digital services are just digital versions of what we already had. They add relatively little extra value to the user compared to it’s non-digital predecessor, other than the fact that they are digital, so you don’t have to go to a physical place, which is sometimes a huge benefit for sure.</p>
                            <p className='mt-6'>But what problem are all 84 other apps on my phone actually solving for me? The app of my bank account for example. In this app I can see my recent transactions and my balance. But my questions about money, though, are much more complicated. Am I earning enough? What if my future changes? Can I pay for the study of my children over 10 years? All these questions are not answered by this app.</p>
                            <p className='mt-6'>The world has changed, so also the online needs of people. We see it as three waves of innovation. I would like to introduce you to our perception of digital products and services. </p>
                            <img src={blog2} className='w-full block my-12' />
                            <h3 className='font-bold mt-8 mb-4 text-2xl md:text-3xl'>1975-2005 - The traditional wave</h3>
                            <p>This is mainly behind us in the Western world. It was a time in which you could use computers without having to write lines of code, but by pointing and clicking.</p>
                            <p className='mt-6'>The opportunities seemed endless: it brought us websites, and could communicate over the internet. Computers started to become faster and more useful. But only a select part of your life was done on a computer. It was a huge innovation for people to see their financial balance at the local ATM in town.</p>
                            <h3 className='font-bold mt-8 mb-4 text-2xl md:text-3xl'>2005-2020 - The digitalization wave</h3>
                            <p>Once technology started to play a bigger role in our lives, companies started to explore the digital space. It was a fundamental shift in how companies operated – it meant the birth of the first trillion-dollar company, and the death of many others. All services that could be done digitally, turned digital. Like online transactions, see all available houses or book a flight .</p>
                            <p className='mt-6'>In this wave, digital services are about function. Making something digital is often seen as the goal, not a means. The human aspect is often forgotten.</p>
                            <h3 className='font-bold mt-8 mb-4 text-2xl md:text-3xl'>2020-? - The humanization wave</h3>
                            <p>The applications are there. They work. The next frontier is about answering the tough questions: can my smart home app help me to live healthier? Can my medical app also detect mental stress? Will my financial app help me reduce financial stress, and prepare for my retirement?</p>
                            <p className='mt-6'>A fairly simple thought exercise to figure out what these apps look like is to ask yourself, what if I hired someone for $500 an hour to be my mental health butler? Just replace “mental health” with any industry or problem we’re facing.</p>
                            <p className='mt-6'>This is the era where technology is finally getting friendly. It will help us truly achieve our goals, provide freedom and help us tackle life’s big challenges. It will make our lives better, without sitting in front of screens all day. Let’s make technology really work for us. So we can become more human again.</p>
                            <p className='mt-6 italic'>This article is from our magazine on humanizing product design. You can order a physical or digital copy for free <a href="//feedbackclub.typeform.com/to/wwc8ikHJ" target='_blank' rel='noopener noreferrer' className='border-b border-black'>here</a>!</p>

                            <div className='flex mt-10 md:mt-20'>
                                <Link to='/thoughts' className='border-black p-2 px-4 inline-block outline-none flex-none font-medium border mr-10 text-lg'>&larr; More thoughts</Link>
                                <form className='hidden md:flex flex-1' onSubmit={async e => {
                                    e.preventDefault();
                                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    const isEmail = re.test(String(this.state.email).toLowerCase());
                                    if(isEmail) {
                                        const firstName = window.prompt("Just one more thing – what's your first name? So we can make the email a little more personal :)") || null;
                                        try {
                                            await firebase.firestore().collection('newsletterSignups').add({
                                                email: this.state.email,
                                                firstName,
                                                createdOn: Date.now() / 1000 | 0, // Unix timestamp in seconds
                                            });
                                            this.setState({email: ''});
                                            window.alert("✅ All set! We send out an email every 3 months with our favorites from the blog – and we'll send you the very next one!");
                                        } catch(e) {
                                            window.alert('⚠️ Oops, something went wrong. Try again or please contact us if the problem persists so we can fix it!');
                                        }
                                    } else {
                                        window.alert("⚠️ Oops, that doesn't look like a real email address!");
                                    }
                                }}>
                                    <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} className='border-black p-2 px-4 outline-none flex-1 w-full border text-lg' placeholder='Your email' />
                                    <button type='submit' className='cursor-pointer select-none border-black p-2 px-4 outline-none flex-none border text-lg font-medium border-l-0'>Subscribe to these articles</button>
                                </form>
                            </div>
                        </div>
                    </div></Animate>
                </div>
                <Footer />
            </>
        );
    }
}

Thought.propTypes = {
    
}

export default Thought;