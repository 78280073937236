import React from 'react';
import PropTypes from 'prop-types';
import img1 from '../img/what1.png';
import img2 from '../img/what2.png';
import img3 from '../img/what3.png';
import num1 from '../img/what1num.png';
import num2 from '../img/what2num.png';
import num3 from '../img/what3num.png';
import Animate from "./Animate";

class Rules extends React.Component {
    render() {
        return (
            <div id='playbook' className='py-24 md:py-32' style={{background: '#F6F4EE'}}>
                <h2 className='text-3xl md:text-4xl font-semibold mb-10 md:mb-16 wrapper md:text-center'>We're different by design.</h2>
                <div className='wrapper grid gap-10 md:grid-cols-3'>
                    <Animate>
                        <div>
                            <img src={img1} />
                            <img src={num1} width={55} className='mt-10' style={{filter: 'invert()'}} />
                            <p className='text-2xl md:text-3xl leading-snug md:leading-snug max-w-sm font-medium mt-5'>We don’t go along with innovation theatre, but act rather than talk.</p>
                        </div>
                    </Animate>
                    <Animate alt={1}>
                        <div>
                            <img src={img3} />
                            <img src={num2} width={70} className='mt-10' style={{filter: 'invert()'}} />
                            <p className='text-2xl md:text-3xl leading-snug md:leading-snug max-w-sm font-medium mt-5'>We understand the board room, but are closer with your customers.</p>
                        </div>
                    </Animate>
                    <Animate alt={2}>
                        <div>
                            <img src={img2} />
                            <img src={num3} width={58} className='mt-10' style={{filter: 'invert()'}} />
                            <p className='text-2xl md:text-3xl leading-snug md:leading-snug max-w-sm md:pr-4 font-medium mt-5'>No busywork. We’re hands-on and expect our clients to be the same.</p>
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

Rules.propTypes = {
    
}

export default Rules;