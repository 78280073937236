import React from 'react';
import PropTypes from 'prop-types';
import s1 from '../img/sector-1.gif';
import s2 from '../img/sector-2.gif';
import s3 from '../img/sector-3.gif';
import s4 from '../img/sector-4.gif';
import Animate from "./Animate";

class Industry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
        };
    }
    render() {
        return (
            <Animate>
                <div onMouseEnter={() => this.setState({isHover: true})} onMouseLeave={() => this.setState({isHover: false})} className='p-10 pl-5 mt-5 md:flex text-lg lg:items-center' style={{minHeight: 325, background: '#F6F4EE'}}>
                    <div className={`flex-1`}>
                        <div className={` ${this.props.className}`}>
                            <img src={this.props.img} />
                        </div>
                    </div>
                    <div className='ml-5 flex-1'>
                        <h4 className='font-medium text-2xl mb-5' style={{maxWidth: 208}}>{this.props.title}</h4>
                        {this.props.children}
                    </div>
                </div>
            </Animate>
        );
    }
}

class Industries extends React.Component {
    render() {
        return (
            <div className='py-20 md:py-32 wrapper'>
                <h2 className='text-3xl md:text-4xl max-w-xl md:text-center leading-snug md:leading-snug mx-auto mb-6 md:mb-10 font-semibold'>We think, strategize and prototype for people and planet.</h2>
                <div className='grid md:grid-cols-2 md:gap-5'>
                    <div>
                        <Industry img={s1} title='Social issues, health and wellbeing'>
                            <ul>
                                <li>Equal opportunity</li>
                                <li>Financial confidence</li>
                                <li>Mental wellbeing</li>
                                <li>Accessible healthcare</li>
                            </ul>
                        </Industry>
                        <Industry img={s2} title='Broad shifts in culture and society' className='p-6 py-0'>
                            <ul>
                                <li>Interfaceless future</li>
                                <li>Social cohesion</li>
                                <li>Ethics and identity</li>
                                <li>Cities and urbanisation</li>
                            </ul>
                        </Industry>
                    </div>
                    <div>
                        <Industry img={s3} title='Future opportunities and disruptions' className='p-10'>
                            <ul>
                                <li>Energy transition</li>
                                <li>Seamless mobility (MaaS)</li>
                                <li>Real estate and insurance</li>
                                <li>Sustainable tourism</li>
                            </ul>
                        </Industry>
                        <Industry img={s4} title='Humane technology' className='p-3'>
                            <p>Technology should empower human behaviour, not interrupt it.</p>
                            <ul className='mt-3'>
                                <li>Humane finances</li>
                                <li>Humane governments</li>
                            </ul>
                        </Industry>
                    </div>
                </div>
            </div>
        );
    }
}

Industries.propTypes = {
    
}

export default Industries;