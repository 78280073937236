import React from 'react';
import Header from "../components/Header";
import Animate from "../components/Animate";
import thoughts from "../img/thoughts.svg";
import Footer from "../components/Footer";
import firebase from 'firebase';
import {withRouter} from "react-router-dom";

class _Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }
    render() {
        return (
            <div className={`${this.props.isLast ? '' : 'border-b border-black'} py-10`}>
                <div className='md:text-lg font-medium'>{this.props.date} <span className='mx-1'>·</span> {this.props.type}</div>
                <div onClick={() => this.props.slug ? this.props.history.push('/thoughts/' + this.props.slug) : this.setState({isOpen: !this.state.isOpen})} className='mt-2 cursor-pointer group'>
                    <h2 className='text-2xl md:text-3xl font-medium leading-snug select-none' style={{lineHeight: 1.5}}>{this.props.title} <span className='group-hover:opacity-100 opacity-0'>{this.props.slug ? <>&rarr;</> : (this.state.isOpen ? <>&uarr;</> : <>&darr;</>)}</span></h2>
                </div>
                {this.props.link && this.state.isOpen ? <>
                    <div className='p-4 px-6 mt-4 mb-6 text-lg' style={{backgroundColor: 'rgba(0,0,0,0.05)'}}>
                        {this.props.note} <span className='opacity-50 ml-2'>— {this.props.author.split(' ')[0]}</span>
                    </div>
                    <a href={this.props.link} target='_blank' rel='noopener noreferrer' className='border-black p-2 px-4 inline-block outline-none flex-none font-medium border-2 text-lg'>{this.props.cta || <>Check out the {this.props.type.toLowerCase()}</>}</a>
                </> : null}
            </div>
        );
    }
}

const Article = withRouter(_Article);

class Thoughts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
    }
    render() {
        return (
            <>
                <Header active='thoughts' />
                <div className='wrapper text-2xl md:text-3xl pb-20 max-w-screen-lg font-medium leading-normal'>
                    <Animate delay={0}>
                        <div className='mx-auto mt-6 w-full' style={{maxWidth: 480}}>
                            <img src={thoughts} style={{maxWidth: 360}} className='w-full' />
                        </div>
                    </Animate>
                    <Animate delay={100}><p className='mt-10 leading-normal' style={{maxWidth: 770}}>From favorite podcasts to deep insights, this is The Prototype Factory’s curated collection for human-first invention.</p></Animate>
                    <Animate delay={180}><div className='flex mt-7 items-center'>
                        <form style={{maxWidth: 370}} className='flex' onSubmit={async e => {
                            e.preventDefault();
                            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            const isEmail = re.test(String(this.state.email).toLowerCase());
                            if(isEmail) {
                                const firstName = window.prompt("Just one more thing – what's your first name? So we can make the email a little more personal :)") || null;
                                try {
                                    await firebase.firestore().collection('newsletterSignups').add({
                                        email: this.state.email,
                                        firstName,
                                        createdOn: Date.now() / 1000 | 0, // Unix timestamp in seconds
                                    });
                                    this.setState({email: ''});
                                    window.alert("✅ All set! We send out an email every 3 months with our favorites from the blog – and we'll send you the very next one!");
                                } catch(e) {
                                    window.alert('⚠️ Oops, something went wrong. Try again or please contact us if the problem persists so we can fix it!');
                                }
                            } else {
                                window.alert("⚠️ Oops, that doesn't look like a real email address!");
                            }
                        }}>
                            <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} className='border-black rounded-none appearance-none p-2 px-4 outline-none flex-1 w-full border text-lg' placeholder='Your email' />
                            <button type='submit' className='cursor-pointer select-none border-black p-2 px-4 outline-none flex-none border text-lg border-l-0'>Subscribe</button>
                        </form>
                        <div onClick={() => {
                            const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
                            window.alert(`Press ${isMac ? 'cmd' : 'ctrl'}-D to add this page to your bookmarks!`);
                        }} className='border-black hidden md:block cursor-pointer -mt-1.5 py-1 ml-10 outline-none flex-none border-b text-lg'>Add to bookmarks</div>
                        <a href='mailto:tycho@prototypefactory.co?subject=Blog tip!' className='border-black hidden md:block -mt-1.5 py-1 ml-8 outline-none flex-none border-b text-lg'>Send us a tip</a>
                    </div></Animate>
                </div>
                <Animate delay={250}>
                    <div style={{backgroundColor: '#F6F4EE'}}>
                        <div className='max-w-screen-lg mx-auto wrapper py-12 md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                            <div className='text-3xl font-bold md:font-medium leading-normal pt-7'>2023</div>
                            <div>
                                <Article
                                    title='Feeding the bookworms 📚'
                                    author='The Prototype Factory'
                                    type='Books'
                                    date='27 July'
                                    slug='books'
                                />
                                <Article
                                    title='Most services are now digital. The next step? Making them human.'
                                    author='The Prototype Factory'
                                    type='Insights'
                                    date='20 July'
                                    slug='waves'
                                />
                                <Article
                                    title='User Friendly – How the hidden rules of design are changing the way we live, work and play'
                                    author='Tycho Litjens'
                                    type='Books'
                                    date='12 July'
                                    note='Gifted by my friend Raffaele. It shows the importance of user friendliness – from nuclear reactors to Disney parks. A must-read for anyone working in product design.'
                                    link='https://www.amazon.com/User-Friendly-Hidden-Design-Changing/dp/0374279756'
                                />
                                <Article
                                    title='Cards for Humanity – A practical tool for inclusive design'
                                    author='Jeroen Riemens'
                                    type='Tools'
                                    date='5 July'
                                    note='Very applied and humanized way to validate products & services for real people with real problems.'
                                    link='https://cardsforhumanity.idean.com'
                                    isLast
                                />
                            </div>
                        </div>
                    </div>
                </Animate>
                <Footer />
            </>
        );
    }
}

Thoughts.propTypes = {

}

export default Thoughts;