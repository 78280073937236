import React, {useState} from 'react';
import contact from '../img/contact.gif';
import line from '../img/line.gif';
import logo from '../img/logo.png';
import bar from '../img/bar.png';
import count from '../img/job-count.png';
import magazine from '../img/magazine.svg';
import {Link, withRouter} from "react-router-dom";
import {openPopupWidget} from "react-calendly";

function Item(props) {
    const [hover, setHover] = useState(props.isActive);
    return (
        <div onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(props.isActive)} className={`cursor-pointer font-medium relative ${props.className || ''}`}>
            {props.children}
            <img src={hover ? line : ''} className='absolute' />
        </div>
    );
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBarHidden: window.sessionStorage.getItem('tpf-bar') === 'hidden',
        };
    }
    getUrl(slug) {
        const baseUrl = window.location.hostname === "localhost" ? '/' : '/';
        if(slug[0] === '/') slug = slug.substr(1);
        return baseUrl + (slug || '');
    }
    render() {
        return (
            <>
                {this.state.isBarHidden ? null : (
                    <div className='py-8 md:py-6 pb-7 mb-2 font-medium flex items-center justify-center px-5' style={{backgroundColor: '#F6F4EE'}}>
                        <img src={magazine} width={24} className='mr-5 md:mr-3 mt-px' />
                        <div>We made a magazine on humanizing products in {new Date().getFullYear()} – <a href='https://feedbackclub.typeform.com/to/wwc8ikHJ' target='_blank' rel='noopener noreferrer' className='border-b border-black'>get it here</a>!</div>
                        <div onClick={() => {
                            this.setState({isBarHidden: true});
                            window.sessionStorage.setItem('tpf-bar', 'hidden');
                        }} className='text-2xl md:text-lg p-1 -m-1 cursor-pointer ml-3 opacity-50'>×</div>
                    </div>
                )}
                <div className='wrapper grid grid-cols-3 text-lg h-32'>
                    <div className='flex invisible md:visible items-center'>
                        <Item isActive={this.props.active === 'cases'} onClick={() => this.props.history.push('/')}  className='mr-16'>Cases</Item>
                        <Item isActive={this.props.active === 'thoughts'} onClick={() => this.props.history.push('/thoughts')}  className='mr-16'>Thoughts</Item>
                        <Item className='relative' isActive={this.props.active === 'jobs'} onClick={() => this.props.history.push('/jobs')}>
                            Jobs
                            <img src={count} width={22} className='absolute' style={{top: 1, left: 44}} />
                        </Item>
                    </div>
                    <div className='flex items-center justify-center'>
                        <Link to='/'><img src={logo} style={{width: 81}} /></Link>
                    </div>
                    <div className='flex justify-end invisible md:visible items-center'>
                        <Item isActive={this.props.active === 'about'} onClick={() => this.props.history.push('/about')} className='mr-16'>About us</Item>
                        <div onClick={() => openPopupWidget({url: 'https://calendly.com/prototype-factory/call'})} className='font-medium cursor-pointer relative'>
                            Get in Touch
                        </div>
                        <img src={contact} className='absolute pointer-events-none mr-2 -mt-px' style={{right: 4, width: 150}} />
                    </div>
                </div>
                <div className='flex md:hidden px-7 pb-8 mt-2 justify-between relative'>
                    <Item isActive={this.props.active === 'cases'} onClick={() => this.props.history.push('/')}>Cases</Item>
                    <Item isActive={this.props.active === 'thoughts'} onClick={() => this.props.history.push('/thoughts')}>Thoughts</Item>
                    <Item isActive={this.props.active === 'jobs'} onClick={() => this.props.history.push('/jobs')}>Jobs</Item>
                    <Item isActive={this.props.active === 'about'} onClick={() => this.props.history.push('/about')}>About</Item>
                    <div onClick={() => openPopupWidget({url: 'https://calendly.com/prototype-factory/call'})} className='font-medium cursor-pointer relative'>
                        Contact
                    </div>
                </div>
            </>
        );
    }
}

Header.propTypes = {
    
}

export default withRouter(Header);