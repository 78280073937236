import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import CaseLetter from './pages/CaseLetter';
import {withRouter} from 'react-router-dom';
import About from "./pages/About";
import Jobs from "./pages/Jobs";
import Thoughts from "./pages/Thoughts";
import Thought from "./pages/Thought";
import ThoughtBooks from "./pages/ThoughtBooks";

class App extends React.Component {
    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return (
            <Switch>
                <Route path={'/'} exact component={Home} />
                <Route path={'/cases/:case'} component={CaseLetter} />
                <Route path={'/about'} exact component={About} />
                <Route path={'/thoughts'} exact component={Thoughts} />
                <Route path={'/thoughts/waves'} exact component={Thought} />
                <Route path={'/thoughts/books'} exact component={ThoughtBooks} />
                <Route path={'/jobs'} exact component={Jobs} />
            </Switch>
        );
    }
}

export default withRouter(App);