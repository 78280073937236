import React from 'react';
import Header from "../components/Header";
import Hero from "../components/Hero";
import Cases from "../components/Cases";
import Rules from "../components/Rules";
import Industries from "../components/Industries";
import Clients from "../components/Clients";
import Jobs from "../components/Jobs";
import Footer from "../components/Footer";

class Home extends React.Component {
    render() {
        return (
            <>
                <Header active='cases' />
                <Hero />
                <Cases />
                <Rules />
                <Industries />
                <Clients />
                <Jobs />
                <Footer />
            </>
        );
    }
}

export default Home;