import React from 'react';
import PropTypes from 'prop-types';
import heart from '../img/heart.png';
import jeroen from '../img/jeroen-2.jpg';
import tycho from '../img/tycho.jpg';
import smiley from '../img/smiley.gif';
import {Link} from "react-router-dom";
import {openPopupWidget} from "react-calendly";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        const date1 = new Date("8/17/2018");
        const date3 = new Date("8/17/2019");
        const date2 = new Date();
        const Difference_In_Time = date2.getTime() - date1.getTime();
        const Difference_In_Time2 = date3.getTime() - date1.getTime();
        this.interviews = parseInt(Difference_In_Time / (1000 * 3600 * 24) / 2);
        this.prototypes = parseInt(Difference_In_Time2 / (1000 * 3600 * 24) / 8.5);
        this.state = {
            copiedTycho: false,
            copiedGeneral: false,
        };
    }
    async copy(isTycho) {
        if(this.timeout) window.clearTimeout(this.timeout);
        await navigator.clipboard.writeText(isTycho ? 'tycho@prototypefactory.co' : 'jeroen@prototypefactory.co');
        if(isTycho) {
            this.setState({copiedTycho: true, copiedGeneral: false});
            this.timeout = window.setTimeout(() => {
                this.setState({copiedTycho: false});
                this.timeout = 0;
            }, 2000);
        } else {
            this.setState({copiedGeneral: true, copiedTycho: false});
            this.timeout = window.setTimeout(() => {
                this.setState({copiedGeneral: false});
                this.timeout = 0;
            }, 2000);
        }
    }
    componentWillUnmount() {
        if(this.timeout) window.clearTimeout(this.timeout);
    }
    render() {
        return (
            <div className='relative'>
                <div style={{background: '#F1C4A1'}} className='absolute hidden md:block inset-0' />
                <div className='absolute hidden md:block inset-0' style={{left: '50%', background: '#000'}} />
                <div className='md:max-w-screen-xl mx-auto md:px-8 md:px-10 relative grid md:grid-cols-3'>
                    <div className='md:col-span-2 pl-8 md:px-0 py-10 md:py-20' style={{background: '#F1C4A1'}}>
                        <img src={heart} style={{width: 56}} />
                        <div className='mt-12 flex items-center'>
                            <div className='rounded-full bg-center bg-cover' style={{width: 64, height: 64, backgroundImage: `url(${tycho})`}} />
                            <div className='ml-5'>
                                <h5 className='text-sm mb-px'>New business</h5>
                                <div onClick={() => this.copy(true)} className={`text-xl font-medium md:text-3xl cursor-pointer`}>{this.state.copiedTycho ? 'Copied!' : 'tycho@prototypefactory.co'}</div>
                            </div>
                        </div>
                        <div className='mt-5 flex items-center'>
                            <div className='rounded-full bg-center bg-cover' style={{width: 64, height: 64, backgroundImage: `url(${jeroen})`}} />
                            <div className='ml-5'>
                                <h5 className='text-sm mb-px'>Anything else</h5>
                                <div onClick={() => this.copy(false)} className={`text-xl transition font-medium md:text-3xl cursor-pointer`}>{this.state.copiedGeneral ? 'Copied!' : 'jeroen@prototypefactory.co'}</div>
                            </div>
                        </div>
                        <div className='flex mt-20'>
                            <div>
                                <h5 className='text-sm mb-1'>Studio</h5>
                                <div className='text-lg font-semibold'>Vredenburg 40</div>
                                <div className='text-lg font-semibold'>3511 BD Utrecht</div>
                            </div>
                            <div className='ml-32 hidden lg:block'>
                                <h5 className='text-sm mb-1'>Prototypes built</h5>
                                <div className='text-4xl mt-1.5 font-medium'>{this.prototypes}</div>
                            </div>
                            <div className='ml-20 hidden lg:block'>
                                <h5 className='text-sm mb-1'>Interviews done</h5>
                                <div className='text-4xl mt-1.5 font-medium'>{this.interviews}</div>
                            </div>
                            <div className='ml-20 hidden lg:block'>
                                <h5 className='text-sm mb-1'>Innovation theatre</h5>
                                <div className='relative'>
                                    <div className='text-4xl mt-1.5 font-medium'>0</div>
                                    <img src={smiley} style={{width: 26}} className='absolute left-9 top-2' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-black flex-col flex p-8 md:p-10 md:p-20 h-full'>
                        <div>
                            <div className='my-2'><Link to='/' onClick={() => document.querySelector('body').scrollIntoView({behavior: 'smooth'})} className='font-light text-lg text-white'>Cases</Link></div>
                            <div className='my-2'><Link to='/jobs' onClick={() => document.querySelector('body').scrollIntoView({behavior: 'smooth'})} className='font-light text-lg text-white'>Jobs</Link></div>
                            <div className='my-2'><Link to='/about' onClick={() => document.querySelector('body').scrollIntoView({behavior: 'smooth'})} className='font-light text-lg text-white'>About us</Link></div>
                            <div className='my-2'><span onClick={() => openPopupWidget({url: 'https://calendly.com/prototype-factory/call'})} className='cursor-pointer font-light text-lg text-white'>Get in touch</span></div>
                            <div className='mt-10'><a href="//feedbackclub.typeform.com/to/wwc8ikHJ" target='_blank' rel='noopener noreferrer' className='block font-light text-lg text-white'>Order our magazine <span className='ml-1'>↗</span></a></div>
                        </div>
                        <p className='opacity-50 pt-10 md:pt-0 text-white mt-auto'>&copy; The Prototype Factory</p>
                    </div>
                </div>
            </div>
        );
    }
}

Footer.propTypes = {
    
}

export default Footer;