import React from 'react';
import PropTypes from 'prop-types';

class Animate extends React.Component {
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            isAnimated: this.props.delay == null,
            canAnimate: false,
        };
        this.child = React.createRef();
    }
    componentDidMount() {
        if(this.props.delay == null) window.addEventListener('scroll', this.handleScroll);
        if(this.props.delay == null) this.checkViewport();
        if(this.props.delay != null) this.timeout = window.setTimeout(() => {
            this.setState({isAnimated: true});
        }, this.props.delay);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        if(this.timeout) window.clearTimeout(this.timeout);
    }
    checkViewport() {
        const OFFSET = 150; // Pixels from top of browser at which animation starts
        const positionFromTop = this.child.current.getBoundingClientRect().top + window.pageYOffset;
        const canAnimate = window.scrollY + window.innerHeight - OFFSET > positionFromTop;
        const isHidden = window.scrollY + window.innerHeight < positionFromTop;
        if(this.state.canAnimate !== canAnimate || this.state.canHide !== isHidden) {
            let hasAnimated = this.state.hasAnimated;
            if(!canAnimate && isHidden) {
                hasAnimated = false;
            } else if(canAnimate && !isHidden) {
                hasAnimated = true;
            }
            if(hasAnimated && !this.state.hasAnimated && !this.timeout2) {
                this.timeout2 = window.setTimeout(() => {
                    this.setState({hasAnimated: true});
                    this.timeout2 = null;
                }, 300 + ((this.props.alt || 0) * 500));
            } else if(!hasAnimated && this.state.hasAnimated) {
                this.setState({hasAnimated: false});
            }
            this.setState({canAnimate, isHidden});
        }
    }
    handleScroll() {
        if(this.child.current) {
            this.checkViewport();
        }
    }
    /*
    false true -> opacity-0 -> set animate to false
    false false -> opacity-0 ALS !animate
    true false -> animate & opacity-1 -> set animate to true
     */
    render() {
        const x = this.props.delay != null ? this.state.isAnimated : this.state.isAnimated && this.state.canAnimate && !this.state.hasAnimated;
        return React.cloneElement(this.props.children, {
            ref: this.child,
            className: `${this.props.children.props.className || ''} ${x ? `animate-down${this.props.alt || ''}` : (this.state.hasAnimated ? '' : 'invisible')}`,
        });
    }
}

Animate.propTypes = {
    
}

export default Animate;