import React from 'react';
import Header from "../components/Header";
import about from '../img/about.png';
import icon1 from '../img/icon1.png';
import icon2 from '../img/icon2.png';
import icon3 from '../img/icon3.png';
import icon4 from '../img/icon4.png';
import icon5 from '../img/icon5.png';
import what1num from '../img/what1num.png';
import what2num from '../img/what2num.png';
import what3num from '../img/what3num.png';
import Footer from "../components/Footer";
import s3 from '../img/sector-3.gif';
import {Link} from "react-router-dom";
import Animate from "../components/Animate";
import jeroen from "../img/jeroen.jpg";
import tycho from "../img/tycho-2.jpg";
import rens from "../img/rens.png";
import jorik from "../img/jorik.png";
import carola from "../img/carola.png";

class Jobs extends React.Component {
    render() {
        return (
            <>
                <Header active='jobs' />
                <div className='mt-4 wrapper text-3xl text-center pb-24 max-w-3xl font-medium leading-normal'>
                    <Animate delay={0}><img src={s3} width={240} className='mx-auto' /></Animate>
                    <Animate delay={1000}><p className='mt-10'>Join our flexible pool of user interviewers, designers and UX researchers.</p></Animate>
                    <Animate delay={2000}><p className='mt-10'>No long reports or buzzwords, ever. If you're obsessed with interviewing real people and crafting real solutions, then we’re a fit.</p></Animate>
                </div>
                <div className='py-24' style={{backgroundColor: '#F6F4EE'}}>
                    <Animate delay={2100}><div className='wrapper max-w-screen-lg md:grid text-lg md:text-base' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl font-semibold mb-10 md:mb-0'>What we're <br />looking for</h3>
                        <div className='max-w-xl text-lg'>
                            <p>We always like to broaden the pool of freelancers we work with, and can't wait to welcome you as a part of our family. You’ll join on full projects for start-ups, visionaries and global brands.</p>
                            <Animate>
                                <div>
                                    <p className='mt-6'>We’re currently recruiting:</p>
                                    <ul className='mt-6'>
                                        <li><span>Freelance UI/UX designers (jr./mdr.)</span></li>
                                        <li><span>Freelance user interviewers (researchers / anthropologists)</span></li>
                                        <li><span>Freelance project managers</span></li>
                                    </ul>
                                </div>
                            </Animate>
                            <Animate>
                                <div>
                                    <p className='mt-6'>Some key things we look for:</p>
                                    <ul className='mt-6'>
                                        <li>Being consumer obsessed</li>
                                        <li>Knowledge of design thinking methodologies</li>
                                        <li>For the designers; we love Figma!</li>
                                    </ul>
                                    <p className='mt-6'>We’re looking forward to hear from you.</p>
                                    <a target='_blank' rel='noopener noreferrer' href='https://feedbackclub.typeform.com/to/jq4ggrAg' className='mt-6 inline-block border-b border-black font-medium'>Apply here</a>
                                </div>
                            </Animate>
                        </div>
                    </div></Animate>
                </div>
                <div className='py-24'>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl mb-16 md:mb-0 font-semibold'>Unrivaled <br />capabilities</h3>
                        <div className='text-lg md:grid grid-cols-2 gap-10'>
                            <div>
                                <Animate>
                                    <div className='flex items-center'>
                                        <img src={icon1} width={60} />
                                        <p className='font-medium ml-10'>Spot market opportunities</p>
                                    </div>
                                </Animate>
                                <Animate>
                                    <div className='mt-10 flex items-center'>
                                        <img src={icon2} width={60} />
                                        <p className='font-medium ml-10'>Ideate on future propositions</p>
                                    </div>
                                </Animate>
                                <Animate>
                                    <div className='mt-10 flex items-center'>
                                        <img src={icon3} width={60} />
                                        <p className='font-medium ml-10'>Truly understand users</p>
                                    </div>
                                </Animate>
                            </div>
                            <div>
                                <Animate>
                                    <div className='mt-10 md:mt-0 flex items-center'>
                                        <img src={icon4} width={48} className='-mt-4 ml-1 md:ml-0 mr-3 md:mr-0' />
                                        <p className='font-medium ml-10'>Prototype <br />technologies</p>
                                    </div>
                                </Animate>
                                <Animate>
                                    <div className='mt-10 flex items-center'>
                                        <img src={icon5} width={48} className='ml-1 md:ml-0 mr-3 md:mr-0' />
                                        <p className='font-medium ml-10'>Validate <br />partnerships</p>
                                    </div>
                                </Animate>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-24' style={{backgroundColor: '#C4D0C4'}}>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl font-semibold mb-12 md:mb-0'>Rooted <br />beliefs</h3>
                        <div className='text-lg'>
                            <Animate><div className='flex items-center'>
                                <img src={what1num} width={48} style={{filter: 'invert(1)'}} />
                                <p className='font-medium ml-10'>Zero bullshit, zero buzzwords. We don’t play along with innovation theatre, but act rather than talk.</p>
                            </div></Animate>
                            <Animate><div className='mt-10 flex items-center'>
                                <img src={what2num} width={56} style={{filter: 'invert(1)'}} />
                                <p className='font-medium ml-8'>We are human-obsessed. We understand the board room, but are closer with your customers.</p>
                            </div></Animate>
                            <Animate><div className='mt-10 flex items-center'>
                                <img src={what3num} width={48} style={{filter: 'invert(1)'}} />
                                <p className='font-medium ml-10'>No busywork. We dive right in to get to the root of the challenge, and expect our clients to do the same.</p>
                            </div></Animate>
                        </div>
                    </div>
                </div>
                <div className='py-24'>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl font-semibold mb-10 md:mb-0'>Meet our <br />inventors</h3>
                        <div className='text-lg font-medium'>
                            <div className='md:grid grid-cols-2 gap-10'>
                                <Animate><a className='block group' href='//linkedin.com/in/jeroenriemens/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-48 bg-center bg-cover' style={{backgroundImage: `url(${jeroen})`}} />
                                    <h4 className='mt-4'>Jeroen Riemens <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                </a></Animate>
                                <Animate><a className='block group' href='//linkedin.com/in/tycholitjens/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-48 mt-6 md:mt-0 bg-center bg-cover' style={{backgroundImage: `url(${tycho})`}} />
                                    <h4 className='mt-4'>Tycho Litjens <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                </a></Animate>
                                <Animate><a className='flex items-center mt-10 md:mt-4 group' href='//linkedin.com/in/rens-gingnagel-41ba7447/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-24 w-24 mr-8 bg-center bg-cover' style={{backgroundImage: `url(${rens})`}} />
                                    <div>
                                        <h4>Rens Gingnagel <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                        <h5 className='opacity-50'>Strategist</h5>
                                    </div>
                                </a></Animate>
                                <Animate><a className='flex items-center mt-4 group' href='//linkedin.com/in/jorikelferink/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-24 w-24 mr-8 bg-center bg-cover' style={{backgroundImage: `url(${jorik})`}} />
                                    <div>
                                        <h4>Jorik Elferink <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                        <h5 className='opacity-50'>User researcher</h5>
                                    </div>
                                </a></Animate>
                                <Animate><a className='flex items-center mt-4 md:mt-0 group' href='//linkedin.com/in/carolaverschoor/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-24 w-24 mr-8 bg-center bg-cover' style={{backgroundImage: `url(${carola})`}} />
                                    <div>
                                        <h4>Carola Verschoor <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                        <h5 className='opacity-50'>Advisor</h5>
                                    </div>
                                </a></Animate>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Jobs;