import React from 'react';
import Header from "../components/Header";
import blog1 from '../img/blog1.jpg';
import blog2 from '../img/blog2.png';
import jeroen from "../img/jeroen-2.jpg";
import tycho from "../img/tycho.jpg";
import book1 from "../img/book1.jpg";
import book2 from "../img/book2.jpg";
import book3 from "../img/book3.jpeg";
import book4 from "../img/book4.jpg";
import book5 from "../img/book5.jpeg";
import Footer from "../components/Footer";
import Animate from "../components/Animate";
import firebase from "firebase";
import {Link} from "react-router-dom";

class Thought extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
    }
    render() {
        return (
            <>
                <Header active='thoughts' />
                <div className='wrapper max-w-screen-lg pt-8 md:pt-24'>
                    <div className='mx-auto max-w-screen-md'>
                        <Animate delay={0}><h2 className='text-4xl md:text-5xl font-bold leading-tight md:leading-snug'>Feeding the Bookworms 📚</h2></Animate>
                        <Animate delay={1000}>
                            <div className='flex items-center mt-5'>
                                <div className='h-10 w-10 -mr-2 relative z-10 border-white border rounded-full flex-none bg-center bg-cover' style={{backgroundImage: `url(${tycho})`}} />
                                <div className='h-10 w-10 mr-3 rounded-full border-white border flex-none bg-center bg-cover' style={{backgroundImage: `url(${jeroen})`}} />
                                <div className='text-lg'>By Tycho Litjens & Jeroen Riemens <span className='mx-1'>·</span> Books <span className='mx-1'>·</span> 27 July 2023</div>
                            </div>
                        </Animate>
                    </div>
                    <Animate delay={1100}><div className='mx-auto max-w-screen-md text-lg md:text-xl pb-24 leading-relaxed' style={{lineHeight: 1.625}}>
                        <div className='max-w-prose'>
                            <p className='text-2xl md:pr-10 mt-8' style={{lineHeight: 1.625}}>As explorers by heart, we always like to discover new views and gather new insights. We have put together a list of our favorite books at the moment to broaden your horizons and learn some new things during this summer period:</p>
                            <div className='flex items-start mt-12'>
                                <img src={book1} className='w-24 md:w-64 mr-12 shadow' />
                                <div>
                                    <h3 className='font-bold text-xl md:text-2xl mb-4' style={{lineHeight: 1.625}}>User Friendly: How the hidden rules of design are changing the way we live work and play - Cliff Kuang with Robert Fabricant</h3>
                                    <p>When your friends or family ask what you really do as a designer, this is the book to give to them. It starts with why the ‘Three Mile Island Nuclear Disaster’ wasn’t a technical or a human fault, but a design fault. It features design philosophies from the grandfathers of design, founders of ‘If This Then That’, Disney and way more. And – it’s very user friendly to read.</p>
                                </div>
                            </div>
                            <div className='flex items-start mt-16'>
                                <div>
                                    <h3 className='font-bold mb-4 text-xl md:text-2xl' style={{lineHeight: 1.625}}>Good Services: How to design services that work  - Lou Downe</h3>
                                    <p>Our founder Tycho might be a bit allergic to ‘models and theories’, and prefers to learn from practical case studies. Many other blogs and books focus on how to make things look good or feel magical. This book is on solving real problems. Tycho: “I would wrap this book and give it to designers of all sorts; teachers, parents, Airbnb-hosts, shop owners. Basically anyone who is human.”</p>
                                </div>
                                <img src={book2} className='w-24 md:w-64 ml-12 shadow' />
                            </div>
                            <div className='flex items-start mt-16'>
                                <img src={book3} className='w-24 md:w-64 mr-12 shadow' />
                                <div>
                                    <h3 className='font-bold mb-4 text-xl md:text-2xl' style={{lineHeight: 1.625}}>The Score Takes Care of Itself - Bill Walsh</h3>
                                    <p>This book is about an American football coach who explains how he let a poorly performing team win the super bowl. We love it because it is very concrete. The book is also full of lists of ‘this is exactly what to do in this situation’. The coach started by tackling all the little things in the organization (like how the receptionists answer the phone), which eventually created a whole different dynamic that radiated on the team itself. If you manage teams of any kind, this one is your golden nugget.</p>
                                </div>
                            </div>
                            <div className='flex items-start mt-16'>
                                <div>
                                    <h3 className='font-bold mb-4 text-xl md:text-2xl' style={{lineHeight: 1.625}}>Focus - Daniel Goleman</h3>
                                    <p>The title says it all: this book explains very well why it is so difficult to get into a flow of ‘deep work’ these days. While getting into this flow is super important for meaningful work ánd why we are shooting ourselves in the foot when we don't prioritize that.</p>
                                </div>
                                <img src={book4} className='w-24 md:w-64 ml-12 shadow' />
                            </div>
                            <div className='flex items-start mt-16'>
                                <img src={book5} className='w-24 md:w-64 mr-12 shadow' />
                                <div>
                                    <h3 className='font-bold mb-4 text-xl md:text-2xl' style={{lineHeight: 1.625}}>Bullshit Jobs - David Graeber</h3>
                                    <p>Once gifted to him as a joke, our founder Jeroen found this book surprisingly insightful: while people might be working 60 hours a week, that doesn’t always mean they do work that adds something of value to our society. Jeroen: “Why bullshit work exists still doesn’t make much sense, but by reading this book you’ll understand the drivers beneath it. A valuable insight & a trigger to ensure that you do as little bullshit work yourself as possible.”</p>
                                </div>
                            </div>
                            <p className='mt-16'>And with that we wish you a lovely summer ahead! Follow us on <a className='border-b border-black' href='https://instagram.com/theprototypefactory' target='_blank' rel='noopener norefferer'>Instagram</a> <span className='hidden md:inline'>or subscribe to our newsletter below</span> for more insights, inspiration and tips ☀️</p>

                            <div className='flex mt-10'>
                                <Link to='/thoughts' className='border-black p-2 px-4 inline-block outline-none flex-none font-medium border mr-10 text-lg'>&larr; More thoughts</Link>
                                <form className='hidden md:flex flex-1' onSubmit={async e => {
                                    e.preventDefault();
                                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    const isEmail = re.test(String(this.state.email).toLowerCase());
                                    if(isEmail) {
                                        const firstName = window.prompt("Just one more thing – what's your first name? So we can make the email a little more personal :)") || null;
                                        try {
                                            await firebase.firestore().collection('newsletterSignups').add({
                                                email: this.state.email,
                                                firstName,
                                                createdOn: Date.now() / 1000 | 0, // Unix timestamp in seconds
                                            });
                                            this.setState({email: ''});
                                            window.alert("✅ All set! We send out an email every 3 months with our favorites from the blog – and we'll send you the very next one!");
                                        } catch(e) {
                                            window.alert('⚠️ Oops, something went wrong. Try again or please contact us if the problem persists so we can fix it!');
                                        }
                                    } else {
                                        window.alert("⚠️ Oops, that doesn't look like a real email address!");
                                    }
                                }}>
                                    <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} className='border-black p-2 px-4 outline-none flex-1 w-full border text-lg' placeholder='Your email' />
                                    <button type='submit' className='cursor-pointer select-none border-black p-2 px-4 outline-none flex-none border text-lg font-medium border-l-0'>Subscribe to these articles</button>
                                </form>
                            </div>
                        </div>
                    </div></Animate>
                </div>
                <Footer />
            </>
        );
    }
}

Thought.propTypes = {

}

export default Thought;