import React from 'react';
import PropTypes from 'prop-types';
import clients from '../img/clients.png';
import nn from '../img/logo-nn.svg';
import abn from '../img/logo-abn.svg';
import bnp from '../img/logo-bnp.svg';
import vvaa from '../img/logo-vvaa.svg';
import pukka from '../img/logo-pukka.svg';
import airbus from '../img/logo-airbus.svg';
import unilever from '../img/logo-unilever.svg';
import spotify from '../img/logo-spotify.svg';
import heineken from '../img/logo-heineken.svg';
import cineville from '../img/logo-cineville.svg';
import nrc from '../img/logo-nrc.svg';
import you from '../img/logo-you.gif';
import Animate from "./Animate";

class Clients extends React.Component {
    render() {
        return (
            <div style={{background: '#C4D0C4'}} className='py-24 md:py-32'>
                <div className='wrapper'>
                    <img src={clients} style={{width: 129}} className='mx-auto' />
                    <h2 className='text-3xl md:text-4xl font-semibold mb-16 md:mb-24 md:text-center mt-12 md:leading-snug'>Familiar faces trust <br />our applied strategies</h2>
                    <Animate>
                        <div className='grid gap-24 grid-cols-2 md:grid-cols-4 max-w-screen-lg mx-auto'>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 30, top: 5, position: 'relative'}} src={abn} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 20}} src={airbus} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 48}} src={bnp} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 20}} src={cineville} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 24}} src={heineken} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 30}} src={nn} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 26}} src={nrc} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img src={pukka} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 70}} src={unilever} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 34}} src={spotify} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img src={vvaa} /></div></Animate>
                            <Animate><div className='flex items-center justify-center'><img style={{height: 104}} src={you} /></div></Animate>
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

Clients.propTypes = {
    
}

export default Clients;