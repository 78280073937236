import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import firebase from 'firebase';
import {BrowserRouter} from "react-router-dom";

const firebaseConfig = {
    apiKey: "AIzaSyCvsKKSXzJ9JH-dChUAudRKVsxIjPoy8xM",
    authDomain: "prototype-factory.firebaseapp.com",
    databaseURL: "https://prototype-factory.firebaseio.com",
    projectId: "prototype-factory",
    storageBucket: "prototype-factory.appspot.com",
    messagingSenderId: "23679223150",
    appId: "1:23679223150:web:e0f76f0234fd3ac8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);