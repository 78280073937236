import React from 'react';
import Header from "../components/Header";
import about from '../img/about.png';
import icon1 from '../img/icon1.png';
import icon2 from '../img/icon2.png';
import icon3 from '../img/icon3.png';
import icon4 from '../img/icon4.png';
import icon5 from '../img/icon5.png';
import what1num from '../img/what1num.png';
import what2num from '../img/what2num.png';
import what3num from '../img/what3num.png';
import timeline from '../img/timeline.png';
import tycho from '../img/tycho-2.jpg';
import jeroen from '../img/jeroen.jpg';
import rens from '../img/rens.png';
import jorik from '../img/jorik.png';
import carola from '../img/carola.png';
import Footer from "../components/Footer";
import Animate from "../components/Animate";

class Question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowing: false,
        };
    }
    render() {
        return (
            <div className={`border-b border-black ${this.state.isShowing ? 'pb-8' : ''} ${this.props.className}`}>
                <div onClick={() => this.setState({isShowing: !this.state.isShowing})} className='flex items-center'>
                    <h4 className='text-2xl flex-1 py-5 select-none font-medium leading-relaxed cursor-pointer'>{this.props.title}</h4>
                    <div className={`text-xl font-bold px-5 ml-10 ${this.state.isShowing ? 'transform rotate-180' : ''}`}>
                        <svg width="20" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.60062 0C2.87078 0.234637 3.10716 0.402235 3.30977 0.636872C7.02437 4.32402 10.739 8.01117 14.4873 11.6983C15.5004 12.7039 15.5004 12.7039 16.5472 11.6983C20.2618 8.01117 23.9427 4.35754 27.6235 0.670391C28.434 -0.134078 28.434 -0.100559 29.2106 0.670391C29.6834 1.13966 30.0886 1.60894 30.5952 2.01117C31.1017 2.48045 31.1355 2.81564 30.5952 3.31844C29.2106 4.65922 27.8599 6.03352 26.4754 7.40782C23.0647 10.7933 19.6202 14.1788 16.2096 17.5642C15.703 18.1006 15.3653 18.1676 14.7913 17.5978C10.0298 12.8715 5.23461 8.11173 0.439403 3.41899C-0.134671 2.84916 -0.16844 2.48045 0.439403 1.94413C1.01348 1.47486 1.48624 0.905028 2.02655 0.402235C2.16163 0.268156 2.36424 0.167598 2.60062 0Z" fill="black"/>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="31" height="18" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                {this.state.isShowing && this.props.children}
            </div>
        );
    }
}

class About extends React.Component {
    render() {
        return (
            <>
                <Header active='about' />
                <div className='wrapper text-3xl text-center pb-24 max-w-4xl font-medium leading-normal'>
                    <Animate delay={0}>
                        <div className='mx-auto mt-4 w-full' style={{maxWidth: 500}}>
                            <img src={about} style={{maxWidth: 400}} className='w-full -ml-4' />
                        </div>
                    </Animate>
                    <Animate delay={1000}><p className='mt-10'>We’re The Prototype Factory. We deliver actionable innovation for future-thinking teams and visionaries.</p></Animate>
                    <Animate delay={2000}><p className='mt-10'>We’re a bunch of optimistic problem-solvers obsessed with how people and products work together.</p></Animate>
                </div>
                <div className='py-24' style={{backgroundColor: '#F1C4A1'}}>
                    <Animate delay={2100}>
                        <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                            <h3 className='text-3xl mb-10 md:mb-0 font-semibold'>Proven <br />approach</h3>
                            <div className='text-lg'>
                                <Animate><p className='font-medium'>We’re known for being able to spot trends and market opportunities, and converting those to validated, unique concepts people want to pay for.</p></Animate>
                                <Animate><p className='mt-6 font-medium'>We come in at the very start of a new concept, using unique methodologies, hyperrealistic prototyping and deep customer research. We leave a few months later, delivering a validated concept ready for you to implement.</p></Animate>
                                <Animate><p className='mt-6 font-medium'>We’re consumer obsessed.</p></Animate>
                            </div>
                        </div>
                    </Animate>
                </div>
                <div className='py-24'>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl mb-16 md:mb-0 font-semibold'>Where we <br />come in</h3>
                        <Animate>
                            <div className='relative'>
                                <div className='flex max-w-xl items-center'>
                                    <p className='-mt-5 md:-mt-10 text-lg font-medium'>Start</p>
                                    <img src={timeline} className='hidden md:block mr-6' />
                                    <div className='block md:hidden ml-4 mr-6'>
                                        <img src={timeline} />
                                    </div>
                                    <p className='-mt-5 md:-mt-10 text-lg font-medium'>End</p>
                                </div>
                                <p className='md:absolute mt-10 md:mt-0 font-medium text-lg max-w-xs left-72 top-40'>
                                    We join during the initial "messy phase" to discover, prototype & validate new product ideas.
                                </p>
                            </div>
                        </Animate>
                    </div>
                </div>
                <div className='py-24' style={{background: '#f6f4ee'}}>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl mb-16 md:mb-0 font-semibold'>Unrivaled <br />capabilities</h3>
                        <div className='text-lg md:grid grid-cols-2 gap-10'>
                            <div>
                                <Animate>
                                    <div className='flex items-center'>
                                        <img src={icon1} width={60} />
                                        <p className='font-medium ml-10'>Spot market opportunities</p>
                                    </div>
                                </Animate>
                                <Animate>
                                    <div className='mt-10 flex items-center'>
                                        <img src={icon2} width={60} />
                                        <p className='font-medium ml-10'>Ideate on future propositions</p>
                                    </div>
                                </Animate>
                                <Animate>
                                    <div className='mt-10 flex items-center'>
                                        <img src={icon3} width={60} />
                                        <p className='font-medium ml-10'>Truly understand users</p>
                                    </div>
                                </Animate>
                            </div>
                            <div>
                                <Animate>
                                    <div className='mt-10 md:mt-0 flex items-center'>
                                        <img src={icon4} width={48} className='-mt-4 ml-1 md:ml-0 mr-3 md:mr-0' />
                                        <p className='font-medium ml-10'>Prototype <br />technologies</p>
                                    </div>
                                </Animate>
                                <Animate>
                                    <div className='mt-10 flex items-center'>
                                        <img src={icon5} width={48} className='ml-1 md:ml-0 mr-3 md:mr-0' />
                                        <p className='font-medium ml-10'>Validate <br />partnerships</p>
                                    </div>
                                </Animate>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-24'>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl mb-16 md:mb-0 font-semibold'>The 100% <br />honest FAQ</h3>
                        <Animate>
                            <div className='-mt-5'>
                                <Question title='You say you’re a "radically different agency". How are you different?'>
                                    <p>Our main differtatior is being strong on the <span className='font-bold'>whole</span> <a href='https://www.designcouncil.org.uk/news-opinion/what-framework-innovation-design-councils-evolved-double-diamond' target='_blank' rel='noopener noreferrer' className='border-b border-black'>double diamond approach</a>. We understand market and user needs with a high empasis on qualitative interviews and are able to respond to discovered problems with high-quality prototypes that truly solve user needs. We find product-user fit for new products and services.</p>
                                    <p className='mt-4'>Our customers tell us we are different because we innovate, rather than play "innovation theatre". No BS, buzzwords, powerpoints or long PDF reports. We dive right in, finding concrete insights you can immediately act upon.</p>
                                    <p className='mt-4'>If you prefer lists, here is a list of characteristics that make us unique:</p>
                                    <ol className='mt-4'>
                                        <li>We’re B2C only.</li>
                                        <li>We’re a 100% independent agency.</li>
                                        <li>We’re quicker. How? By cutting all the innovation theatre crap.</li>
                                        <li>We deliver actionable innovation. No weird matrix models and jargon.</li>
                                        <li>We’ll become obsessed with your project. Promised.</li>
                                    </ol>
                                </Question>
                                <Question title='What does a typical project look like?'>
                                    <p>All projects are unique and require a tailor-made approach, but since you asked for a typical project: we generally come in when the hard questions arrive. “Why should we solve this, how and with who?”</p>
                                    <p className='mt-4'>We then help to set up a roadmap to tackle the problem. What are your goals? What do you need to know? What do you want to get out of it? What capabilities and resources do you have available?</p>
                                    <p className='mt-4'>From there we use a mix of methods to empathize, prototype and validate different solutions that benefit the end users, stakeholders, possible partners, and most importantly, put a smile on everybody’s face. We empower you to bring your validated solution with a clear story to management, developers and future team members. We leave when the solution is validated and works perfectly, ready to implement.</p>
                                    <p className='mt-4'>Projects last around 1-3 months, and start at €15.000.</p>
                                </Question>
                                <Question title='Why do companies who already have innovation teams and great designers still work with you?'>
                                    <p>We can talk about this for hours, but we'll try to keep it simple.</p>
                                    <p className='mt-4'><span className='italic'>In-house design and innovation teams</span> tend to work on present-day products with a relatively short-term backlog. And that’s great.</p>
                                    <p className='mt-4'><span className='italic'>The Prototype Factory</span> focuses on the creation of new disruptive products, functionalities or services by finding deeper user needs, and solving them with actionable prototypes.</p>
                                    <p className='mt-4'>We love to see how your in-house capabilities can strengthen our process.</p>
                                </Question>
                                <Question title='What are usually your deliverables?'>
                                    <p>To keep this concrete and zero bullshit, we made a list of our previous project’s deliverables in every stage:</p>

                                    <ul className='mt-4'>
                                        <li>A creative 24-page PDF outlining 12 different concepts.</li>
                                        <li>18 interview video’s: 3 with industry experts, 15 with users.</li>
                                        <li>3 different simple prototypes for the initial validation, in Figma.</li>
                                    </ul>

                                    <ul className='mt-4'>
                                        <li>1 final developed prototype, 25 screens.</li>
                                        <li>A final 45-page PDF, explaining the final concept and it’s implications.</li>
                                        <li>A final 8-page PDF, containing strategic nuances of the business value of the concept.</li>
                                    </ul>

                                    <ul className='mt-4'>
                                        <li>12 mock-ups, 25 quotes, 1 interview mash-up video for internal use.</li>
                                    </ul>

                                    <p className='mt-4'>This was a 2-month project.</p>
                                </Question>
                                <Question isLast title='Do you only work for big corporates?'>
                                    <p>We don't! We have worked with lots of smaller organizations in the past in retail, entertainment and in solving social issues. Don't let the logo's on the homepage fool you – we happily adapt to teams of any size. Even if you’re just a single stubborn founder with a crazy idea, we’ll help you.</p>
                                </Question>
                            </div>
                        </Animate>
                    </div>
                </div>
                <div className='py-24' style={{backgroundColor: '#C4D0C4'}}>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl font-semibold mb-12 md:mb-0'>Rooted <br />beliefs</h3>
                        <div className='text-lg'>
                            <Animate><div className='flex items-center'>
                                <img src={what1num} width={48} style={{filter: 'invert(1)'}} />
                                <p className='font-medium ml-10'>Zero bullshit, zero buzzwords. We don’t play along with innovation theatre, but act rather than talk.</p>
                            </div></Animate>
                            <Animate><div className='mt-10 flex items-center'>
                                <img src={what2num} width={56} style={{filter: 'invert(1)'}} />
                                <p className='font-medium ml-8'>We are human-obsessed. We understand the board room, but are closer with your customers.</p>
                            </div></Animate>
                            <Animate><div className='mt-10 flex items-center'>
                                <img src={what3num} width={48} style={{filter: 'invert(1)'}} />
                                <p className='font-medium ml-10'>No busywork. We dive right in to get to the root of the challenge, and expect our clients to do the same.</p>
                            </div></Animate>
                        </div>
                    </div>
                </div>
                <div className='py-24'>
                    <div className='wrapper max-w-screen-lg md:grid' style={{gridTemplateColumns: '2fr 3fr'}}>
                        <h3 className='text-3xl font-semibold mb-10 md:mb-0'>Meet our <br />inventors</h3>
                        <div className='text-lg font-medium'>
                            <div className='md:grid grid-cols-2 gap-10'>
                                <Animate><a className='block group' href='//linkedin.com/in/jeroenriemens/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-48 bg-center bg-cover' style={{backgroundImage: `url(${jeroen})`}} />
                                    <h4 className='mt-4'>Jeroen Riemens <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                </a></Animate>
                                <Animate><a className='block group' href='//linkedin.com/in/tycholitjens/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-48 mt-6 md:mt-0 bg-center bg-cover' style={{backgroundImage: `url(${tycho})`}} />
                                    <h4 className='mt-4'>Tycho Litjens <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                </a></Animate>
                                <Animate><a className='flex items-center mt-10 md:mt-4 group' href='//linkedin.com/in/rens-gingnagel-41ba7447/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-24 w-24 mr-8 bg-center bg-cover' style={{backgroundImage: `url(${rens})`}} />
                                    <div>
                                        <h4>Rens Gingnagel <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                        <h5 className='opacity-50'>Strategist</h5>
                                    </div>
                                </a></Animate>
                                <Animate><a className='flex items-center mt-4 group' href='//linkedin.com/in/jorikelferink/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-24 w-24 mr-8 bg-center bg-cover' style={{backgroundImage: `url(${jorik})`}} />
                                    <div>
                                        <h4>Jorik Elferink <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                        <h5 className='opacity-50'>User researcher</h5>
                                    </div>
                                </a></Animate>
                                <Animate><a className='flex items-center mt-4 md:mt-0 group' href='//linkedin.com/in/carolaverschoor/' target='_blank' rel='noopener norefferer'>
                                    <div className='h-24 w-24 mr-8 bg-center bg-cover' style={{backgroundImage: `url(${carola})`}} />
                                    <div>
                                        <h4>Carola Verschoor <span className='group-hover:opacity-100 opacity-0 transform duration-150'>&rarr;</span></h4>
                                        <h5 className='opacity-50'>Advisor</h5>
                                    </div>
                                </a></Animate>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default About;