import React from 'react';
import PropTypes from 'prop-types';
import Header from "../components/Header";
import nn from "../img/logo-nn.svg";
import nnCase from "../img/case-nn.png";
import {Case} from '../components/Cases';
import Footer from "../components/Footer";
import pukka from "../img/logo-pukka.svg";
import pukkaCase from "../img/case-pukka.png";
import abn from "../img/logo-abn.svg";
import arval from "../img/logo-arval.svg";
import vvaa from "../img/logo-vvaa.svg";
import grip from "../img/logo-grip.svg";
import gripCase from "../img/case-grip.png";
import tycho from '../img/tycho-letter.png';
import tychoName from '../img/tycho-name.png';
import { openPopupWidget } from "react-calendly";
import Animate from "../components/Animate";

class CaseLetter extends React.Component {
    render() {
        const c = this.props.match.params.case;
        return (
            <>
                <Header active='cases' />
                <div className='md:flex pt-6 pb-12 md:py-16 md:pb-32 max-w-6xl wrapper justify-center'>
                    <p className='max-w-lg text-lg md:mt-10'>
                        <Animate delay={300}><span>Hi there!</span></Animate>
                        <br /><br />
                        <Animate delay={2000}><span>I know... seeing = believing.</span></Animate>
                        <br /><br />
                        <Animate delay={4000}><span>But our legal friends are still busy puzzling what parts of our cases we can show without breaking NDA’s or upsetting talent we worked with.</span></Animate>

                        <br /><br />
                        <Animate delay={8000}><span>I do like your adventurious attitude and I love to talk with you about product invention. I’m happy to meet each other in a Zoom call!</span></Animate>

                        <br /><br />
                        <Animate delay={12000}><span>I’ll personally guide you through our favorite projects and you’re free to ask any questions you might have. No pressure, no sales techniques, I hate that too.</span></Animate>

                        <br /><br />
                        <Animate delay={16000}><span>You can pick a time that suits you <span onClick={() => openPopupWidget({url: 'https://calendly.com/prototype-factory/call'})} className='border-b-2 border-blue-500 hover:border-blue-600 font-bold text-blue-500 hover:text-blue-600 cursor-pointer'>here</span>.</span></Animate>

                        <br /><br />
                        <Animate delay={18000}><span>Again, thanks for your interest,</span></Animate>
                        <br />

                        <Animate delay={19000}><span className='inline-flex mt-10'>
                            <span>
                            <img className='mb-3 mt-4 ml-2' src={tychoName} width={84} />
                        Creative Director
                        <br />
                        <a className='text-blue-500 hover:text-blue-600' href='tel:+31625235315'>+31 6 252 353 15</a>
                            </span>
                            <img src={tycho} className='w-40 h-40 ml-12 transform rotate-3' />
                        </span></Animate>
                    </p>
                    
                    {c === 'nationale-nederlanden' && <Case className='md:ml-28 hidden md:block transform rotate-3' isLetter color='#FDF4ED' desc='Helping employees tackle and overcome financial debt' logo={nn} img={nnCase} />}
                    {c === 'pukka' && <Case className='md:ml-28 hidden md:block transform rotate-3' isLetter color='#9BAABF' imageWidth={90} desc='Bringing wellbeing to the core of the ceremony of tea' logo={pukka} img={pukkaCase} />}
                    {c === 'abn-amro' && <Case className='md:ml-28 hidden md:block transform rotate-3' isLetter color='#FDF4ED' desc='Using behavioural design convince Dutch consumers to insulate their homes' logo={abn} video='case-abn' />}
                    {c === 'arval' && <Case className='md:ml-28 hidden md:block transform rotate-3' isLetter color='#C4D0C4' imageWidth={140} xl desc='Prototyping the future of mobility for 12 million lease drivers' logo={arval} video='case-arval' />}
                    {c === 'vvaa' && <Case className='md:ml-28 hidden md:block transform rotate-3' isLetter color='#F1C4A1' imageWidth={90} md desc='Tackling the complex issues of overworked general practioners' logo={vvaa} video='case-vvaa' />}
                    {c === 'grip' && <Case className='md:ml-28 hidden md:block transform rotate-3' isLetter color='#C4D0C4' imageWidth={90} desc='Designing the future of finance for 500.000+ customers' logo={grip} img={gripCase} />}
                </div>
                <Footer />
            </>
        );
    }
}

CaseLetter.propTypes = {
    
}

export default CaseLetter;