import React from 'react';
import PropTypes from 'prop-types';
import jobs from '../img/jobs.gif';
import job1 from '../img/job1.png';
import job2 from '../img/job2.png';
import jobimg from '../img/jobs-img.png';
import Animate from "./Animate";
import {Link} from 'react-router-dom';

class Jobs extends React.Component {
    render() {
        return (
            <div className='relative'>
                <div className='wrapper items-center md:grid grid-cols-2 py-16 md:py-32'>
                    <div>
                        <img src={jobs} style={{width: 240}} />
                        <h2 className='max-w-md text-3xl font-semibold leading-snug mt-10'>We are looking for A+ players, problem-solvers, future optimists, applied thinkers and critical enthusiasts.</h2>
                        <div className='flex items-center mt-10'>
                            <img src={job1} style={{width: 63}} />
                            <span className='ml-5'>
                                <h4 className='text-lg font-medium'>User Researcher / Interviewer (NL)</h4>
                                <span>Freelancer &middot; Remote</span>
                            </span>
                        </div>
                        <div className='flex items-center mt-6'>
                            <span className='block' style={{width: 63}}>
                                <img src={job2} style={{width: 49}} />
                            </span>
                            <span className='ml-5'>
                                <h4 className='text-lg font-medium'>Medior Designer</h4>
                                <span>Freelancer &middot; Utrecht</span>
                            </span>
                        </div>
                        <Link className='mt-10 inline-block border-b border-black font-medium' to='/jobs'>View all jobs <span className='transition-all inline-block'>&rarr;</span></Link>
                    </div>
                    <img src={jobimg} className='mt-16 md:mt-0' />
                </div>
            </div>
        );
    }
}

Jobs.propTypes = {
    
}

export default Jobs;