import React from 'react';
import Animate from "./Animate";
import lottie from 'lottie-web';
import title from '../img/title.png';
import abn from "../img/logo-abn.svg";
import picnic from "../img/logo-picnic.svg";
import cineville from "../img/logo-cineville.svg";
import spotify from "../img/logo-spotify.svg";
import you from "../img/logo-you.gif";

class Hero extends React.Component {
    componentDidMount() {
        const isMobile = window.innerWidth < 680;
        lottie.loadAnimation({
          container: document.getElementById(isMobile ? 'animation-mobile' : 'animation'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: '/hero.json'
        });
    }
    render() {
        return (
            <>
                <div className='wrapper md:grid grid-cols-2 items-center pb-4 md:pb-20 mt-6 md:mt-12'>
                    <div className='md:hidden block overflow-hidden h-96 -mt-8 -mx-8 -mb-4 animate-down'>
                        <div id='animation-mobile' className='-m-28 -mx-16 -mt-10 max-w-xl' />
                    </div>
                    <div className='md:pr-20 text-2xl md:text-3xl max-w-2xl font-medium leading-normal md:leading-normal'>
                        <Animate delay={200}><img src={title} style={{width: 337, maxWidth: '100%'}} alt='Humanizing' /></Animate>
                        <Animate delay={250}><h1 className='text-4xl md:text-5xl font-bold mb-10'>Products & Services</h1></Animate>
                        <Animate delay={300}><p>The Prototype Factory is a radically different innovation agency. We come up with, design and prototype new products for people and planet.</p></Animate>
                    </div>
                    <Animate intro delay={400}>
                        <div className='hidden md:flex items-center overflow-hidden h-full'>
                            <div id='animation' className='-m-16 -mb-6' />
                        </div>
                    </Animate>
                </div>
                <div className='hidden md:grid gap-24 grid-cols-2 pb-12 md:grid-cols-5 max-w-screen-lg mx-auto'>
                    <Animate delay={450}><div className='flex items-center justify-center'><img style={{height: 20}} src={cineville} /></div></Animate>
                    <Animate delay={500}><div className='flex items-center justify-center'><img style={{height: 34}} src={spotify} /></div></Animate>
                    <Animate delay={550}><div className='flex items-center justify-center'><img style={{height: 30, top: 5, position: 'relative'}} src={abn} /></div></Animate>
                    <Animate delay={600}><div className='flex items-center justify-center'><img style={{height: 48}} src={picnic} /></div></Animate>
                    <Animate delay={650}><div className='flex items-center justify-center'><img style={{height: 104}} src={you} /></div></Animate>
                </div>
            </>
        );
    }
}

/*
<div className='wrapper pb-10 pt-4'>
        <img src={hero} width={300} className='mx-auto' />
        <div className='max-w-4xl mx-auto mt-16'>
            <p className='text-2xl max-w-2xl leading-normal'>The Prototype Factory is a radical different agency.  We believe that products should and can be invented in a radically hands-on and human-obsessed way.</p>
            <p className='text-2xl mt-10 max-w-2xl leading-normal'>We team up with teams when ideas are in the very earliest stage. Together we think, draw and prototype products for people and planet.</p>
        </div>
    </div>
 */

Hero.propTypes = {
    
}

export default Hero;